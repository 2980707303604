@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  /* 預設的字型大小 */
  font-size: 16px;
  --min-size: 12;
  --max-size: 18;
  --font-size-width: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100vw - 300px) / (440 - 300)));
  --font-size-height: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100vh - 500px) / (800 - 500)));
  --font-size-height2: calc((var(--min-size) * 1px) + (var(--max-size) - var(--min-size)) * ((100dvh - 500px) / (800 - 500)));
  font-size: clamp(var(--min-size) * 1px, min(var(--font-size-width), var(--font-size-height), var(--font-size-height2)), var(--max-size) * 1px);

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}