#lineChart {
  width: 100% !important;
}

.scroll-box::-webkit-scrollbar {
  height: 10px;
  width: 5px;
}

.scroll-box::-webkit-scrollbar-thumb {
  background: #dcdcdc;
}

.outsideContainer {
  max-width: 440px;
  min-width: 300px;
}

.mainContainer {
  background: #FEFFF8;
}

.collapse-title,
:where(.collapse > input[type="checkbox"]) {
  width: 100% !important;
  height: 100% !important;
}


.collapse-arrow>.collapse-title:after {
  box-shadow: 3px 3px #423928 !important;
  color: #423928;
  width: 13px !important;
  height: 13px !important;
  top: 3rem !important;
  right: 30px !important;
}

.collapse-arrow>.collapse-title:before {
  content: "";
  position: absolute;
  transform: translate(50%, -50%);
  width: 30px;
  height: 30px;
  background: #FECB66;
  right: 33px;
  top: 3rem;
  border-radius: 100%;
}

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.2s cubic-bezier(0.250, 0.460, 0.450, 1);
  animation: slide-in-bottom 0.2s cubic-bezier(0.250, 0.460, 0.450, 1);
}

/* ----------------------------------------------
 * Generated by Animista on 2024-2-5 16:27:47
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(100%) rotate(10deg);
    transform: translateY(100%) rotate(3deg);
  }

  100% {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(100%) rotate(10deg);
    transform: translateY(100%) rotate(10deg);
  }

  100% {
    -webkit-transform: none;
  }
}

.scale-out-hor-left {
  -webkit-animation: scale-out-hor-left 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) 0.5s forwards;
  animation: scale-out-hor-left 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) 0.5s forwards;
}

.scale-out-hor-left1 {
  -webkit-animation: scale-out-hor-left 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) 0.3s forwards;
  animation: scale-out-hor-left 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) 0.3s forwards;
}


/* ----------------------------------------------
 * Generated by Animista on 2024-3-5 3:4:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-out-hor-left
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-hor-left {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}

@keyframes scale-out-hor-left {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    opacity: 1;
  }
}


.shadowCard0 {
  box-shadow: 0px 6px 0px -2px rgba(242, 162, 150, 1), 0px 6px 0px 0px #423928;
}

.shadowCard1 {
  box-shadow: 0px 6px 0px -2px #CDB6DB, 0px 6px 0px 0px #423928;
}

.shadowCard2 {
  box-shadow: 0px 6px 0px -2px #ADCFD6, 0px 6px 0px 0px #423928;
}

.shadowCard3 {
  box-shadow: 0px 6px 0px -2px #F3B283, 0px 6px 0px 0px #423928;
}

.rope {
  width: 10px;
  height: 10px;
  background: hsl(39, 25%, 21%);
  position: absolute;
  left: 0.25rem;
  top: calc(50% - 5px);
  border-radius: 100%;
}

.rope::after {
  content: "";
  width: 65px;
  height: 4px;
  background: hsl(0, 0%, 100%);
  border-radius: 5px;
  position: absolute;
  box-shadow: 0px 0px 2px 1px hsl(39, 25%, 21%);
  top: 3px;
  right: 5px
}

.contentRope {
  width: 10px;
  height: 10px;
  background: hsl(39, 25%, 21%);
  position: absolute;
  top: 0.25rem;
  border-radius: 100%;
  box-shadow: 0px -2.75rem #423928;
  visibility: hidden;
}

.contentRope.rope1 {
  left: 10px;
}

.contentRope.rope2 {
  right: 10px;
}

.contentRope::after {
  content: "";
  width: 4px;
  height: 2.85rem;
  background: hsl(0, 0%, 100%);
  border-radius: 5px;
  position: absolute;
  box-shadow: 0px 0px 2px 1px hsl(39, 25%, 21%);
  bottom: 3px;
  right: 3px;
  visibility: hidden;
}

.myCollapse.active .contentRope {
  visibility: visible;
}

.myCollapse.active .contentRope::after {
  visibility: visible;
}

.circle {
  position: absolute;
  height: 1300px;
  width: 750px;
  border-bottom-right-radius: 1300px;
  border-top-right-radius: 1300px;
  top: 50%;
  left: 25px;
  transform: translate(-100%, -50%);
  box-shadow: 0px 0px 0px 12px #FECB66, 0px 0px 0px 24px #ADD6B8;
}

.bg-CDB6DB {
  background: #CDB6DB;
}

.bg-ADD6B8 {
  background: #ADD6B8;
}

.bg-F3B283 {
  background: #F3B283;
}

.bg-F2A296 {
  background: #F2A296;
}

.collBox {
  transition: all 0.15s cubic-bezier(0.550, 0.085, 0.680, 0.530);
  position: relative;
  max-height: 0px;
  overflow: hidden;
}

.myCollapse.active .collBox {
  transition: all 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  overflow: visible;
  max-height: 200px;
}

.collIcon {
  transition: all 0.15s cubic-bezier(0.550, 0.085, 0.680, 0.530);
}

.myCollapse.active .collIcon {
  transform: rotate(180deg);
  transition: all 0.15s cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

.pageBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px 1px;
  border-radius: 5px;
}

.pageBtn:hover,
.pageBtn:active {
  background-color: #f0f0f0;
}

.appSelect {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}